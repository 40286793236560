import MediaType, {PlaceholdersImage} from '@/views/apps/media/constants/MediaType';

export default {
  name: 'MediaConfigurationMixin',
  props: {
    mediaType: {
      type: String,
      default: null,
    },
  },
  computed: {
    placeholder() {
      return PlaceholdersImage(this.mediaType);
    },
    linkEnabled() {
      switch (this.mediaType) {
        case MediaType.video:
          return true;
        case MediaType.audio:
          return true;
        default:
          return false;
      }
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    hasMainCollective() {
      return this.mainCollective?.key !== this.collective.key
        && Object.entries(this.mainCollective).length > 0;
    },
  },
};
