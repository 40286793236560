<template>
  <component
    :is="mediaRendererComponent"
    :media="media"
    :title="title"
    :class="media.mediaType === 'presentation' ? 'media-renderer p-1' : ''"
    :isCompletable="isCompletable"
    @last="$emit('last')"
  />
</template>

<script>
import MediaType from '@/views/apps/media/constants/MediaType';
import MediaConfigurationMixin from '@/views/apps/media/mixins/MediaConfigurationMixin';
import MediaVideoRenderer from './MediaVideoRenderer.vue';
import MediaPdfRenderer from './MediaPdfRenderer.vue';
import MediaPhotosRenderer from './MediaPhotosRenderer.vue';
/* import MediaCollaborativeDocuments from './MediaCollaborativeDocuments.vue'; */
import MediaAudioRenderer from './MediaAudioRenderer.vue';

export default {
  name: 'MediaRenderer',
  components: {
    MediaVideoRenderer,
  },
  mixins: [MediaConfigurationMixin],
  props: {
    media: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    mediaRendererComponent() {
      const data = this.media;

      if (data.expectedTypo == null) {
        data.expectedTypo = this.mediaType;
      }

      switch (this.media.expectedTypo) {
        case MediaType.video:
          return MediaVideoRenderer;
        case MediaType.presentation:
          return MediaPdfRenderer;
        case MediaType.document:
          return MediaPdfRenderer;
        case 'spreadsheet':
          return MediaPdfRenderer;
        case MediaType.audio:
          return MediaAudioRenderer;
        case MediaType.photo:
          return MediaPhotosRenderer;
        case MediaType.storage:
          return MediaPdfRenderer;
        default:
          throw new Error(`Media Type [${this.mediaType}] not supported`);
      }
    },
    isCompletable() {
      return this.media.isCompletable;
    },
  },
};
</script>
<style lang="scss">
.media-renderer {
  border-radius: 1.2rem;
  background-color: rgb(112, 112, 112);
}
</style>
