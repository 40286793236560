<template>
  <div class="photo d-flex justify-content-center pointer">
    <img 
      :src="media.url" 
      :alt="media.name" 
      @click="handlePhotoCardClick($event)"
    >
        <photos-carousel-modal
        :visible.sync="isCarouselVisible"
        :value.sync="currentCarouselIndex"
        :photos="photo"
        :controls="false"
      />
  </div>
  
</template>

<script>
import PhotosCarouselModal from '@/views/apps/photos/components/PhotosCarouselModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'MediaPhotosRenderer',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  components: { PhotosCarouselModal },
  data() {
    return {
      isCarouselVisible: false,
      currentCarouselIndex: 0,
      photo:[]
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    mediaName() {
      return translateTranslationTable(this.currentLocale, this.media.name);
    }
  },
  methods: {
    handlePhotoCardClick(_photo) {
      this.photo.push(this.media)
      this.isCarouselVisible = true;
    },
  },
};
</script>
<style lang="scss">
.photo {
  width: 100%;
  height: auto;
  border-radius: 1.2rem;
  overflow: hidden;
  img {
    max-width: 100%;
    width: auto;
    max-height: 68vh;
    border-radius: 1.2rem;
  }
}
</style>
