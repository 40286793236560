import axiosBackend from './axiosBackend';

export async function giveCompleted(params) {
  try {
    const { data } = await axiosBackend.post('/giveCompleted', { ...params });
    return data;
  } catch (error) {
    return error.response ? error.response : error;
  }
}

export default {
  giveCompleted,
};
