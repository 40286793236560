<template>
  <div v-if="media.isLink">
    <div v-if="isSpotify">
      <iframe
        :src="media.url"
        width="100%"
        height="380"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </div>
    <div v-else-if="isSoundcloud">
      <div v-if="isIframe" v-html="media.url" />
      <iframe
        v-else
        :src="media.url"
        width="100%"
        height="380"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </div>
    <div v-else-if="isYoutube">
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="media.url"
        allowfullscreen
        class="video-border"
      />
    </div>
    <div v-else class="px-2 align-items-center d-flex flex-column">
      <!-- Thumbnail -->
      <div class="photo-audio d-flex justify-content-center">
        <img
          v-if="media.thumbnailURL"
          :src="media.thumbnailURL"
          :alt="media.name"
        >
      </div>
      <!-- External link -->
      <b-link
        :href="media.url"
        class="h-100"
        target="_blank"
      >
        <b-button
          class="mb-2 mt-1"
          variant="primary"
        >
          <feather-icon icon="PlayCircleIcon" size="16" class=" mr-50 text-light" />
          PLAY
        </b-button>
      </b-link>
      <!--    <audio controls class="mt-4 mb-2 w-100">
        <source :src="media.url">
      </audio> -->
    </div>
  </div>
  <div v-else class="px-2">
    <audio controls class="mt-4 mb-2 w-100" 
      :controlsList="media.canDownload?'':'nodownload'"
    >
      <source :src="media.url">
    </audio>
  </div>
</template>

<script>
//import { mapGetters } from 'vuex';
//import { giveCompleted } from '@/services/backend/giveCompleted';

export default {
  name: 'MediaAudioRenderer',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    //...mapGetters(['currentCollective']),

    isYoutube() {
      if (this.media.isLink && this.media.sourceURL.includes('youtube.com')) {
        return true;
      }
      return false;
    },
    isSoundcloud() {
      if (this.media.isLink && this.media.sourceURL.includes('soundcloud.com')) {
        return true;
      }
      return false;
    },
    isSpotify() {
      if (this.media.isLink && this.media.sourceURL.includes('spotify.com')) {
        return true;
      }
      return false;
    },
    isIframe() {
      if(this.media.url.indexOf('<iframe') < 0){
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.photo-audio {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
  img {
    max-width: 100%;
    width: auto;
    max-height: 50vh;
    border-radius: 1.2rem;
  }
}
</style>
