<template>
  <div>
    <!-- <pre style="height: 200px; overflow:scroll;">media: {{ media }}</pre> -->
    <BEmbed
      v-if="showEmbedIframe"
      type="iframe"
      aspect="16by9"
      :src="`${media.url}`"
      allowfullscreen
      class="video-border"
    />
    <VideoPlayerNew2
      v-else
      :source-src="sourceSrc"
      :player-options="playerOptions"
      :is-youtube="isYoutube"
      :is-vimeo="isVimeo"
      @completed="onCompleted"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { giveCompleted } from '@/services/backend/giveCompleted';
import VideoPlayerNew2 from './VideoPlayerNew2.vue';

export default {
  name: 'MediaVideoRenderer',
  components: {
    VideoPlayerNew2,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    isCompletable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapGetters(['currentCollective']),

    showEmbedIframe() {
      return this.media.isLink && (this.isVimeo || !this.isCompletable);
    },
    isYoutube() {
      if (this.media.isLink && this.media.sourceURL.includes('youtube.com')) {
        return true;
      }
      return false;
    },
    isVimeo() {
      if (this.media.isLink && this.media.sourceURL.includes('vimeo.com')) {
        return true;
      }
      return false;
    },
    isSoundcloud() {
      if (this.media.isLink && this.media.sourceURL.includes('soundcloud.com')) {
        return true;
      }
      return false;
    },
    isSpotify() {
      if (this.media.isLink && this.media.sourceURL.includes('spotify.com')) {
        return true;
      }
      return false;
    },
    sourceSrc() {
      let sourceSrc = '';
      if (this.media.isLink) {
        sourceSrc = this.media.sourceURL;
      } else {
        sourceSrc = this.media.sourceURL || this.media.url;
      }
      return sourceSrc;
    },
    playerOptions() {
      return {};
    },
    orderedListItemsKey() {
      if (this.$store.getters.media?.[this.media.mediaType]) {
        return this.$store.getters.media?.[this.media.mediaType]?.unpaginated.map((i) => i.key);
      }
      return [];
    },
  },

  methods: {
    async onCompleted() {
      // console.log('onCompleted...');
      try {
        await giveCompleted({
          communityKey: this.currentCollective.key,
          key: this.media.key,
          morphType: "media",
        });
        const nextKey = this.getNextKey();
        if (nextKey) {
          this.$router.push({
            name: 'video-details',
            params: { communityId: this.$route.params.communityId, itemKey: nextKey },
          });
        }
        if (this.media.key === this.orderedListItemsKey[this.orderedListItemsKey.length - 1]) {
          this.$emit('last');
        }
      } catch (error) {
        // console.log('onCompleted (error): ', error);
        return error;
      }
    },

    getNextKey() {
      const indexCurrent = this.orderedListItemsKey.findIndex((i) => i === this.media.key);
      // console.log('getNextKey (indexCurrent): ', indexCurrent);
      return this.orderedListItemsKey[indexCurrent + 1];
    },
  },
};
</script>
